// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  alert:true,
  toasts:true,
  popover:true,
});

document.querySelector('#btnGenerateNumbers').addEventListener("click", function () { getNumbers(false); });

document.querySelector('#r1').addEventListener("blur", validateNumber);
document.querySelector('#r2').addEventListener("blur", validateNumber);
document.querySelector('#r3').addEventListener("blur", validateNumber);
document.querySelector('#r4').addEventListener("blur", validateNumber);
document.querySelector('#r5').addEventListener("blur", validateNumber);

document.querySelector('#r1').addEventListener("input", onlyNumberKey);
document.querySelector('#r2').addEventListener("input", onlyNumberKey);
document.querySelector('#r3').addEventListener("input", onlyNumberKey);
document.querySelector('#r4').addEventListener("input", onlyNumberKey);
document.querySelector('#r5').addEventListener("input", onlyNumberKey);

document.querySelector('#quantityCombinations').addEventListener("input", onlyNumberKey);
document.querySelector('#quantityRepeats').addEventListener("input", onlyNumberKey);
document.querySelector('#primes').addEventListener("input", onlyNumberKey);

document.querySelector('#btnClear').addEventListener("click", function () {
  document.querySelector('#r1').value = '';
  document.querySelector('#r2').value = '';
  document.querySelector('#r3').value = '';
  document.querySelector('#r4').value = '';
  document.querySelector('#r5').value = '';

  document.querySelector('#quantityCombinations').value = '';
  document.querySelector('#quantityRepeats').value = '';
  document.querySelector('#averageCombination').value = '';
  document.querySelector('#primes').value = '';

  const table = document.querySelector('#tblNums')
  while (table.lastChild) {
    table.removeChild(table.lastChild);
  }

});

getLastResults();
getNumbers(true);

function onlyNumberKey() {
  this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

}

function validateNumber(e) {
  const count = {};
  const numbers = [];
  numbers.push(document.querySelector('#r1').value || 0);
  numbers.push(document.querySelector('#r2').value || 0);
  numbers.push(document.querySelector('#r3').value || 0);
  numbers.push(document.querySelector('#r4').value || 0);
  numbers.push(document.querySelector('#r5').value || 0);

  numbers.forEach((element) => {
    if (element > 0) {
      count[element] = (count[element] || 0) + 1;
    }
  });

  if (
    Object.values(count).filter(
      (value) => value > 1
    )?.length > 0
  ) {
    e.target.value = '';

    document.querySelector('#alertNumbers').innerHTML = 'No se permiten números repetidos';
    document.querySelector('#alertNumbers').classList.remove('d-none');
    setTimeout(() => {
      document.querySelector('#alertNumbers').innerHTML = '';
      document.querySelector('#alertNumbers').classList.add('d-none');
    }, 3000);
  } else {
    let alertMessage;

    for (let i = 0; i < numbers.length; i++) {
      for (let j = i + 1; j < numbers.length; j++) {
        if (
          numbers[i] > 0 &&
          numbers[j] > 0 &&
          numbers[i] > numbers[j]
        ) {
          alertMessage = `El número ${numbers[j]} debe estar ordenado en forma ascendente.`;
          break;
        }
      }
    }
    if (
      numbers[0] === 28 ||
      numbers[1] === 28 ||
      numbers[2] === 28 ||
      numbers[3] === 28
    ) {
      alertMessage =
        "El número 28 solo puede asignarse en R5.";
    }
    if (
      numbers[0] === 27 ||
      numbers[1] === 27 ||
      numbers[2] === 27
    ) {
      alertMessage =
        "El número 27 solo puede asignarse en R4 o R5.";
    }
    if (numbers[0] === 26 || numbers[1] === 26) {
      alertMessage =
        "El número 26 solo puede asignarse en R3 o R4 o R5.";
    }
    if (numbers[0] === 25) {
      alertMessage =
        "El número 25 solo puede asignarse en R2 o R3 o R4 o R5.";
    }

    if (
      numbers[1] === 1 ||
      numbers[2] === 1 ||
      numbers[3] === 1 ||
      numbers[4] === 1
    ) {
      alertMessage =
        "El número 1 solo puede asignarse en R1.";
    }

    if (
      numbers[2] === 2 ||
      numbers[3] === 2 ||
      numbers[4] === 2
    ) {
      alertMessage =
        "El número 2 solo puede asignarse en R1 o R2.";
    }
    if (numbers[3] === 3 || numbers[4] === 3) {
      alertMessage =
        "El número 3 solo puede asignarse en R1 o R2 o R3.";
    }

    if (numbers[4] === 4) {
      alertMessage =
        "El número 4 solo puede asignarse en R1 o R2 o R3 o R4.";
    }

    if (alertMessage) {
      document.querySelector('#alertNumbers').innerHTML = alertMessage;
      document.querySelector('#alertNumbers').classList.remove('d-none');
      setTimeout(() => {
        document.querySelector('#alertNumbers').innerHTML = '';
        document.querySelector('#alertNumbers').classList.add('d-none');
      }, 3000);


    }
  }
}

function getLastResults() {
  fetch("https://genchispazo.com/api/last_result.php")
    .then((response) => response.json())
    .then((data) => {
      document.querySelector('#txtSorteo').innerHTML = data.CONCURSO;
      document.querySelector('#txtR1').innerHTML = data.R1;
      document.querySelector('#txtR2').innerHTML = data.R2;
      document.querySelector('#txtR3').innerHTML = data.R3;
      document.querySelector('#txtR4').innerHTML = data.R4;
      document.querySelector('#txtR5').innerHTML = data.R5;
      document.querySelector('#txtFecha').innerHTML = data.FECHA;

    })
    .catch((e) => {
      document.querySelector('#alertGenerate').innerHTML = "Ocurrió un error al obtener el último resultado.";
      document.querySelector('#alertGenerate').classList.remove('d-none');
      setTimeout(() => {
        document.querySelector('#alertGenerate').innerHTML = '';
        document.querySelector('#alertGenerate').classList.add('d-none');
      }, 3000);
    });

}


function getNumbers(isInit = false) {

  document.querySelector('#textSpinner').classList.add('visually-hidden');
  document.querySelector('#spinner').style.display = 'block';
  const searchParams = new URLSearchParams();

  if (!isInit) {
    const r1 = document.querySelector('#r1').value;
    const r2 = document.querySelector('#r2').value;
    const r3 = document.querySelector('#r3').value;
    const r4 = document.querySelector('#r4').value;
    const r5 = document.querySelector('#r5').value;

    let _numbers = [r1, r2, r3, r4, r5];

    _numbers = _numbers.map((number) => number || 0);
    if (_numbers?.length > 0) {
      searchParams.append("numbers", _numbers);
    }

    const quantityCombinations = document.querySelector('#quantityCombinations').value;
    if (parseInt(quantityCombinations) > 0) {
      searchParams.append("combinations", quantityCombinations);
    }
    const primes = document.querySelector('#primes').value;
    if (primes > 0) {
      searchParams.append("primes", primes);
    }

    const quantityRepeats = document.querySelector('#quantityRepeats').value
    if (parseInt(quantityRepeats) >= 0) {
      searchParams.append("repeats", quantityRepeats);
    }
    const averageCombination = document.querySelector('#averageCombination').value
    if (averageCombination) {
      searchParams.append("average", averageCombination);
    }

  }

  // setIsLoading(true);
  setTimeout(() => {
    fetch(
      "https://genchispazo.com/api/numbers.php?" + searchParams.toString()
    )
      .then((response) => response.json())
      .then((data) => {
        const table = document.querySelector('#tblNums')
        while (table.lastChild) {
          table.removeChild(table.lastChild);
        }
        if (data && data.length > 0) {

          data.forEach((element, index) => {

            const tr = document.createElement("tr");
            let td = document.createElement("td");
            td.innerText = index + 1
            tr.appendChild(td)



            td = document.createElement("td");
            td.innerText = element.CONCURSO || "No ha ganado"
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.FECHA || "-"
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.R1
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.R2
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.R3
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.R4
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.R5
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.MEDIA
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.PRIMOS
            tr.appendChild(td)

            td = document.createElement("td");
            td.innerText = element.REPETIDOS
            tr.appendChild(td)

            table.appendChild(tr)

          });
        }else{
          document.querySelector('#alertTable').innerHTML = "No se encontraron resultados con los parámetros introducidos";
          document.querySelector('#alertTable').classList.remove('d-none');
          setTimeout(() => {
            document.querySelector('#alertTable').innerHTML = '';
            document.querySelector('#alertTable').classList.add('d-none');
          }, 5000);
        }
        document.querySelector('#textSpinner').classList.remove('visually-hidden');
        document.querySelector('#spinner').style.display = 'none';
        // setIsLoading(false);
      })
      .catch((e) => {
        document.querySelector('#textSpinner').classList.remove('visually-hidden');
        document.querySelector('#spinner').style.display = 'none';
        // setIsLoading(false);
        document.querySelector('#alertGenerate').innerHTML = "Ocurrió un error al generar los números";
        document.querySelector('#alertGenerate').classList.remove('d-none');
        setTimeout(() => {
          document.querySelector('#alertGenerate').innerHTML = '';
          document.querySelector('#alertGenerate').classList.add('d-none');
        }, 3000);


      });
  }, 1000);
}
